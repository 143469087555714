import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import CustomInput from "../../../components/CustomInput";
import Res from "../../../resource";
import { Tabs, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AuthWrap,
  AuthWrapInner,
  AuthContainer,
  FormGroup,
  OR,
  FormLink,
  Spinner,
} from "./authStyle";
import { Formik } from "formik";
import {
  userLoginDataAPI,
  loginInfoSuccess,
  loginInfoFailed,
} from "../../../redux/action/signin/loginData";

import { ToastContainer, toast, collapseToast } from "react-toastify";

function Login() {
  const [isToasting, setIsToasting] = useState(false);
  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const loginSelector = useSelector((state: any) => state?.loginData);

  const getLoginToaster = (type: string) => {
    switch (type) {
      case "email":
        setTimeout(() => {
          toast.error("Please enter correct email address & password.");
        }, 1000);
        break;
      case "password":
        setTimeout(() => {
          toast.error("Please enter correct email address & password.");
        }, 1000);
        break;
      case "active":
        setTimeout(() => {
          toast.error("Your account has been disable. Please contact admin.");
        }, 1000);
        break;
      case "pending-list":
        setTimeout(() => {
          toast.error("Your account is pending approval");
        }, 1000);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch({ type: "RESET_LOGIN_PROFILE" });
  }, []);

  useEffect(() => {
    if (loginSelector?.msg?.msg === "Email does not exist.") {
      getLoginToaster("email");
      dispatch({ type: "RESET_LOGIN_PROFILE" });
    } else if (loginSelector?.msg?.msg === "Password is not match.") {
      getLoginToaster("password");
      dispatch({ type: "RESET_LOGIN_PROFILE" });
    } else if (loginSelector?.msg?.msg === "User must be Active") {
      getLoginToaster("active");
      dispatch({ type: "RESET_LOGIN_PROFILE" });
    } else if (
      loginSelector?.msg?.msg ===
      "You're account is under review and if we can confirm it, you will be able to access your account within approximately 24 hours."
    ) {
      getLoginToaster("pending-list");
      dispatch({ type: "RESET_LOGIN_PROFILE" });
    }
  }, [loginSelector]);

  const submitForm = async (values: any) => {
    const finalValue = {
      ...values,
      email: values.email.toLowerCase(),
    };
    dispatch(userLoginDataAPI(finalValue));
  };

  const [showEye, setShowEye] = useState(false);

  const handleEyeChange = () => {
    setShowEye(!showEye);
  };

  return (
    <>
      <AuthWrap>
        <Header />
        <AuthWrapInner>
          <AuthContainer>
            <h2>Login</h2>

            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validate={(values: any) => {
                let errors: any = {};

                // const regex =
                //   /^(([^<>()\    [\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                const email =
                  /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/;
                const passwordRegex =
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,17}$/;

                if (!values.email.trim()) {
                  errors.email = "Please enter email address";
                }
                // } else if (!email.test(values.email)) {
                //   errors.email = "Please enter correct email address";
                // }

                if (!values.password) {
                  errors.password = "Please enter your password";
                }
                // else if (!passwordRegex.test(values.password)) {
                //   errors.password =
                //     "Password must be contain 8 to 17 character with 1 special letter, 1 number, 1 capital and 1 lowercase letter";
                // }
                else if (values.password.length < 8) {
                  errors.password = "Please enter eight digit password";
                }

                return errors;
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                dirty,
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <CustomInput
                      inputid="email"
                      inputlabe="Email Address"
                      inputtype="email"
                      placeholder="Enter Email"
                      value={values.email}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors.email}
                      touched={touched.email}
                    />

                    <CustomInput
                      inputid="password"
                      inputlabe="Password"
                      inputtype={showEye ? "text" : "password"}
                      placeholder="Enter your Password"
                      value={values.password}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors.password}
                      touched={touched.password}
                      handleEyeChange={handleEyeChange}
                      showEye={showEye}
                      maxLength={16}
                    />

                    <FormLink>
                      <Link to="/forgotpassword">Forgot password?</Link>
                    </FormLink>

                    <Button
                      type="submit"
                      className="auth-btn"
                      // disabled={isLoading}
                    >
                      {loginSelector?.isLoading ? (
                        <Spinner animation="border" role="status" />
                      ) : null}
                      Login
                    </Button>
                    {/* <ToastContainer autoClose={5000} /> */}
                  </form>
                </>
              )}
            </Formik>
          </AuthContainer>
        </AuthWrapInner>
        <Footer />
      </AuthWrap>
    </>
  );
}

export default Login;
