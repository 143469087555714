import { useEffect, useState } from "react";
import { Tabs, Modal, Dropdown, Button, Form } from "react-bootstrap";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";

import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../components/CustomInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  AuthWrap,
  AuthWrapInner,
  AuthContainer,
  FormGroup,
  FormLink,
  SignupFormNote,
  Spinner,
} from "./authStyle";
import { Formik } from "formik";
import {
  inviteSignupInfoAPIData,
  inviteSignupInfoFailed,
  inviteSignupInfoSuccess,
} from "../../../redux/action/inviteSignup/inviteSignupAction";

function SignupInvite() {
  const history = useNavigate();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const [isToasting, setIsToasting] = useState(false);
  const [getResetToster, setResetToster] = useState(false);

  const inviteSignUp = useSelector((state: any) => state?.inviteSign);

  const dddd = useSelector((state: any) => state);

  const getToast = (type: string) => {
    if (!isToasting) {
      switch (type) {
        case "success":
          setIsToasting(true);
          toast.success("A verification link has been sent to your account");
          closeToast();
          break;
        case "error":
          setIsToasting(true);
          toast.error("Email Address already used");
          closeToast();
          break;
        default:
          break;
      }
    }
  };

  const closeToast = () => {
    setTimeout(() => {
      setIsToasting(false);
    }, 5000);
  };

  useEffect(() => {
    if (!inviteSignUp?.isLoading) {
      if (inviteSignUp?.data) {
        getToast("success");
        setTimeout(() => {
          navigate("/");
          dispatch(inviteSignupInfoFailed(""));
        }, 5000);
      } else if (inviteSignUp?.error) {
        getToast("error");
        dispatch(inviteSignupInfoSuccess(""));
      }
    }
  }, [inviteSignUp]);

  const submitForm = (values: any) => {
    dispatch(
      inviteSignupInfoAPIData({
        email: values.email.toLowerCase(),
      })
    );
  };

  const login = async (data: any) => {
    console.log(data, "data");
  };

  return (
    <>
      <AuthWrap>
        <Header />
        <AuthWrapInner className="SignupFormInvite">
          <AuthContainer>
            <h2>Signup</h2>
            <Formik
              initialValues={{
                email: "",
              }}
              validate={(values: any) => {
                let errors: any = {};

                const regex =
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (!values.email.trim()) {
                  errors.email = "Please enter email address";
                } else if (!regex.test(values.email)) {
                  errors.email = "Please enter valid email address";
                }
                return errors;
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                dirty,
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <FormGroup className="mb-0">
                      <Form.Label>Email Address</Form.Label>
                      <CustomInput
                        inputid="email"
                        inputlabe=""
                        inputtype="email"
                        placeholder="Enter Email"
                        value={values.email}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors.email}
                        touched={touched.email}
                      />
                    </FormGroup>

                    <SignupFormNote>
                      You will receive an email where you can enter your info to
                      sign up. Tripd is currently by invite or approval only
                    </SignupFormNote>

                    <Button type="submit" className="auth-btn">
                      {inviteSignUp.isLoading ? (
                        <Spinner animation="border" role="status" />
                      ) : null}
                      Request A Link
                    </Button>
                    {/* <ToastContainer autoClose={5000} /> */}

                    <FormLink className="backToLogin">
                      Get back to <Link to="/">Login</Link>
                    </FormLink>
                  </form>
                </>
              )}
            </Formik>
          </AuthContainer>
        </AuthWrapInner>
        <Footer />
      </AuthWrap>
    </>
  );
}

export default SignupInvite;
