import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";
import MainSearchPanel from "../../../components/mainSearchPanel/index";
import RentalThumbOne from "../../../assets/images/rentals-detail-thumb.jpg";

import CreateNewModal from "../../../components/createNewModal/index";
import ImageGalleryModal from "../../../components/imageGalleryModal/index";

import JasonPamel from "../../../assets/images/jason-pamel.png";
import SandraJoseph from "../../../assets/images/sandra-joseph.png";
import PeterJohn from "../../../assets/images/peter-john.png";
import CandyBrian from "../../../assets/images/candy-brian.png";
import Kathy from "../../../assets/images/kathy.png";
import James from "../../../assets/images/james.png";
import CalendarIcon from "../../../assets/images/calendar-icon.svg";
import SportsCompanyImage from "../../../assets/images/sports-detail-logo.jpg";
import SportsEventMain from "../../../assets/images/sports-event-main.jpg";
import SportsEventThumbOne from "../../../assets/images/sports-event-thumb1.jpg";
import SportsEventThumbTwo from "../../../assets/images/sports-event-thumb2.jpg";
import SportsEventThumbThree from "../../../assets/images/sports-event-thumb3.jpg";
import InviteOthersModal from "../../../components/inviteOthersModal/index";

import {
  MyPageWrap,
  MainContentPanel,
  FormGroup,
  HomeListingWrap,
  HomeListingTags,
  CreateBtn,
  CreateBtnInner,
  ModalBody,
  ModalBodyBtn,
  FriendItem,
  CreatePlanDate,
  ButtonTabs,
  TabButton,
  InviteOtherSearch,
  MyGroupTabContent,
  MyGroupTabContentInner,
  InviteMyGroups,
  InviteGroupMembers,
  InviteGroupRadioBox,
  InviteGroupItem,
  InviteGroupMemberItem,
  InviteGroupCheckBox,
  GroupMemberItem,
  MyFollowersLeft,
  MyFollowersRight,
  MyFollowersItemWrap,
  MyFollowersItem,
  SportsDetails,
  SportsDetailsTop,
  SportsLogo,
  SportsLogoInner,
  SportsGallery,
  SportsGalleryTop,
  SportsGalleryBottom,
  SportsGalleryItem,
  SportsTitleWrap,
  SportsTitle,
  SportsInfo,
  SportsInfoWrap,
  SportsReview,
  SportsRating,
  SportsPrice,
  SportsTeamDesc,
  RentalsFeature,
  ImageGalleryWrap,
  BackToList,
  DetailRentalsFeature,
  DetailSportsInfo,
  DetailSportsInfoWrap,
  DetailSportsRating,
  DetailSportsReview,
  DetailSportsPrice,
} from "../hotelResultDetails/hotelResulDetailsStyle";

import moment from "moment";
import ShareWithModal from "../../../components/shareWithModal/index";
import Concert from "../../../assets/images/concert.jpg";
import { getGroupData } from "../../../redux/action/get-all-group/getAllGroups";
import { planListingData } from "../../../redux/action/plan-listing/planListing";
import ChatsPlanModal from "../../../components/chatsPlanModal";
import PlansModal from "../../../components/plansModal/index";
import AnotherDayModal from "../../../components/anotherDayModal";
import GetAllCollectionsModal from "../../../components/collectionsModal";
import CreatePlanModal from "../../../components/createPlanModal/index";
import RentalImage from "../../../assets/images/rental-result-1.jpg";
import { getHotelIdData } from "../../../redux/action/getHotelId/getHotelId";
import RentalImageTwo from "../../../assets/images/rental-result-2.jpg";
import NoImageFound from "../../../assets/images/no-result-found.png";

const HotelResultDetails = (props: any) => {
  const [finalData, setFinalData] = useState<any[]>([]);
  const [planId, setPlanId] = useState("");
  const [timeDayModal, setTimeDayModal] = useState(false);
  const [inviteOthersModalShow, setInviteOthersModalShow] = useState(false);
  const [getPlanDetails, setPlanDetails] = useState("Private");
  const [getEmails, setEmail] = useState("");
  const [getGroupId, setGroupId] = useState("");

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const [createNewModalShow, setCreateNewModalShow] = React.useState(false);

  const [imageGalleryModalShow, setImageGalleryModalShow] =
    React.useState(false);

  const planData = (e: any) => {
    setFinalData(e.days);
    setPlanId(e._id);
    setTimeDayModal(true);
  };

  const [groupImages, setGroupImages] = useState("");
  const [allItems, setAllItems] = useState("");
  const [dynamicLocation, setDynamicLocation] = useState("");
  const [openChatModel, setOpenChatModel] = useState(false);
  const [placeIdData, setPlaceIdData] = useState("");
  const [plansModal, setPlansModal] = useState(false);
  const [getRentalDetails, setRentalDetails] = useState({
    venue_id: "",
    name: "",
    location: "",
    province: "",
    Image: "",
    venue_type: "",
  });
  const [rentalItemId, setRentalItemId] = useState("");
  const [createPlanModalShow, setCreatePlanModalShow] = useState(false);
  const [createData, setCreateData] = useState("");
  const [getCollectionModal, setCollectionModal] = useState(false);
  const [getAllId, setAllId] = useState<string[]>([]);
  const [inviteFriend, setInviteFriend] = useState<string[]>([]);
  const [inviteFollower, setInviteFollower] = useState<string[]>([]);
  const [inviteEmails, setInviteEmails] = useState<any[]>([]);
  const [inviteGroupMembers, setGroupMembers] = useState<string[]>([]);
  const [pendingEmailRequest, setPendingEmail] = useState<any[]>([]);
  const [showimage, setShowImage] = useState("");
  const [getImageUrl, setImageUrl] = useState("");
  const [imageValidation, setImageValidation] = useState("");
  const [getImage, setImage] = useState("");
  const [shareWithModalShow, setShareWithModalShow] = useState(false);
  const [shareWithName, setShareWithName] = useState("");
  const [shareWithLink, setShareWithLink] = useState("");
  const [shareWithAddress, setShareWithAddress] = useState("");
  const [getAllImages, setAllImages] = useState([]);
  const [openTab, setOpenTab] = useState({
    group: false,
    follower: false,
    friend: false,
    email: false,
  });
  const [emailValidate, setEmailValidate] = useState("");
  const [getAllEmail, setAllEmail] = useState<any[]>([]);
  const [getAllUniqueGroupMember, setAllUniqueGroupMember] = useState<any[]>(
    []
  );
  const [getAllGroupMember, setAllGroupMember] = useState<any[]>([]);
  const [backButton, setBackButton] = useState(false);

  const createPlan = useSelector((state: any) => state?.createPlan);

  useEffect(() => {
    if (createData !== "" && createPlan?.data) {
      setFinalData(createPlan?.data?.data[0]?.days);
      setPlanId(createPlan?.data?.data[0]?._id);
      setTimeDayModal(true);
      setCreateData("");
      dispatch({ type: "CREATE_PLAN_RESET" });
    }
  }, [createPlan]);

  useEffect(() => {
    const unqiueMembers = [
      ...getAllGroupMember
        .reduce((a, c) => {
          a.set(c._id, c);
          return a;
        }, new Map())
        .values(),
    ];

    setAllUniqueGroupMember(unqiueMembers);
  }, [getAllGroupMember]);

  const id: any = useLocation();

  const hotelIdHandler = (id: any) => {
    dispatch(getHotelIdData(id));
  };

  useEffect(() => {
    if (id.pathname.split("/").length > 2) {
      hotelIdHandler(id.pathname.split("/")[2]);
    }
  }, [id]);

  const navigateToShareWith = (item: any) => {
    setShareWithModalShow(true);
    setShareWithName(item?.name);
    setShareWithLink(item?._links["event:webpage"]?.href);
    setShareWithAddress(
      `${item?._embedded?.venue?.city} ${item?._embedded?.venue?.name} ${item?._embedded?.venue?.postal_code}`
    );
  };

  const groupPlanDetails = () => {
    dispatch(getGroupData({ search: "" }));
    dispatch(planListingData());
  };
  const shareChatImages = (item: any) => {
    if (item?.HotelImages) {
      const allImages = [
        ...item?.HotelImages?.EXTERIOR,
        ...item?.HotelImages?.NONE,
        ...item?.HotelImages?.ROOMS,
        ...item?.HotelImages?.POOL,
      ];
      setGroupImages(allImages[0].link);
      setAllItems(item);
      setDynamicLocation("/hotel-result-details");
    } else {
      setGroupImages("");
      setAllItems(item);
      setDynamicLocation("/hotel-result-details");
    }
  };

  // const getAllRentalId = useSelector((state: any) => state?.getRentalId);
  const rentalData = useSelector((state: any) => state?.getRentals);
  const hotelResultData = useSelector((state: any) => state?.getHotelId);

  useEffect(() => {
    hotelResultData?.data?.data?.map((item: any) => {
      let allImages: any;
      if (item?.HotelImages) {
        allImages = [
          ...item?.HotelImages?.EXTERIOR,
          ...item?.HotelImages?.NONE,
          ...item?.HotelImages?.ROOMS,
          ...item?.HotelImages?.POOL,
        ];

        setAllImages(allImages);
      }
    });
  }, [hotelResultData?.data?.data]);

  const [getSearchResult, setSearchResult] = useState({
    search: "",
    location: "hotel-result-details",
  });

  return (
    <>
      <Header logout={props.logout} />
      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <MainSearchPanel
            setSearchResult={setSearchResult}
            getSearchResult={getSearchResult}
            // setPageNumber={setPageNumber}
          />

          <HomeListingWrap>
            <HomeListingTags>
              <Link to="/" className="btn">
                All
              </Link>
              <Link to="/concertsresults/1" className="btn">
                Concerts
              </Link>
              <Link to="/foodanddrinks/1" className="btn">
                Food &amp; Drinks
              </Link>
              <Link to="/festivalsresults/1" className="btn">
                Festivals
              </Link>
              <Link to="/sportsresults/1" className="btn">
                Sports
              </Link>
              <Link to="/rentalsresults/1" className="btn">
                Rentals
              </Link>
              <Link to="/hotelsresult/1" className="btn select">
                Hotels
              </Link>
              <Link to="/interestresults/1" className="btn">
                Points of Interest
              </Link>
              <Link to="/hiking/1" className="btn">
                Hiking
              </Link>
            </HomeListingTags>

            <CreateBtn>
              <BackToList
                onClick={() => {
                  setBackButton(false);
                  navigate(-1);
                }}
              >
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.55283 9.1117L15.4567 9.1117C15.945 9.1117 16.3398 9.5065 16.3398 9.99481C16.3398 10.4831 15.945 10.8779 15.4567 10.8779L6.54244 10.8779C6.05413 10.8779 5.65932 10.4831 5.65932 9.99481C5.65932 9.5065 6.06452 9.1117 6.55283 9.1117Z"
                    fill="#6C53E4"
                  />
                  <path
                    d="M9.52378 6.14041C9.75235 6.14041 9.98092 6.22352 10.1472 6.40015C10.49 6.743 10.49 7.30404 10.1472 7.65729L7.7991 10.0053L10.1472 12.3534C10.49 12.6962 10.49 13.2573 10.1472 13.6105C9.8043 13.9534 9.24326 13.9534 8.89001 13.6105L5.91858 10.6391C5.57573 10.2962 5.57573 9.73521 5.91858 9.38196L8.89001 6.41053C9.06663 6.23391 9.29521 6.14041 9.52378 6.14041Z"
                    fill="#6C53E4"
                  />
                  <path
                    d="M10.9997 1.86265e-08C16.5166 1.86265e-08 21.0049 4.48831 21.0049 9.9948C21.0049 15.5117 16.5166 20 10.9997 20C5.4828 20 0.994491 15.5117 0.994491 9.9948C1.00488 4.48831 5.49319 1.86264e-08 10.9997 1.86265e-08ZM10.9997 18.2234C15.5399 18.2234 19.2283 14.5351 19.2283 9.9948C19.2283 5.45455 15.5399 1.76623 10.9997 1.76623C6.45943 1.76623 2.77112 5.45455 2.77112 9.9948C2.7815 14.5351 6.46982 18.2234 10.9997 18.2234Z"
                    fill="#6C53E4"
                  />
                </svg>
                Back
              </BackToList>
              <CreateBtnInner>
                <button
                  type="submit"
                  className="btn"
                  onClick={() => setCreateNewModalShow(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                  >
                    <g transform="translate(-1016 -337)">
                      <path
                        d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z"
                        transform="translate(1016 337)"
                        fill="#fff"
                      />
                      <path
                        d="M5.38-8.554V-4.21H1.148v2.732H5.38V2.865H8.335V-1.479h4.232V-4.21H8.335V-8.554Z"
                        transform="translate(1020.143 350.845)"
                        fill="#4d27da"
                        stroke="#fff"
                        stroke-width="0.5"
                        className="plusIcon"
                      />
                    </g>
                  </svg>
                  CREATE
                </button>
              </CreateBtnInner>
            </CreateBtn>

            <SportsDetails>
              <SportsDetailsTop>
                <SportsLogo>
                  {getAllImages?.map((image: any, index: any) => {
                    return (
                      index === 0 && (
                        <SportsLogoInner>
                          <img src={image?.link} alt={image?.title} />
                        </SportsLogoInner>
                      )
                    );
                  })}

                  {getAllImages?.length === 0 && (
                    <SportsLogoInner>
                      <img src={RentalImageTwo} alt={"default image"} />
                    </SportsLogoInner>
                  )}
                </SportsLogo>

                <SportsGallery>
                  {getAllImages?.map((image: any, index: any) => {
                    return (
                      index === 1 && (
                        <SportsGalleryTop>
                          <img src={image?.link} alt={image?.title} />
                        </SportsGalleryTop>
                      )
                    );
                  })}

                  {getAllImages?.length === 0 && (
                    <SportsGalleryTop>
                      <img src={RentalImageTwo} alt={"default image"} />
                    </SportsGalleryTop>
                  )}

                  <SportsGalleryBottom>
                    {getAllImages?.slice(2, 5).map((image: any, index: any) => {
                      return (
                        <SportsGalleryItem className="morephotos">
                          <img
                            src={image?.link ?? RentalThumbOne}
                            alt={image?.title ?? "default image"}
                          />
                          {index === 2 && (
                            <button
                              type="submit"
                              className="btn"
                              onClick={() => setImageGalleryModalShow(true)}
                            >
                              + photos
                            </button>
                          )}
                        </SportsGalleryItem>
                      );
                    })}
                    {getAllImages.length < 6 &&
                      getAllImages
                        ?.slice(
                          getAllImages.length -
                            (3 - getAllImages?.slice(2, 5).length),
                          5
                        )
                        .map((image: any, index: any) => {
                          return (
                            <SportsGalleryItem>
                              <img src={NoImageFound} alt={"default image"} />
                            </SportsGalleryItem>
                          );
                        })}

                    {getAllImages?.length === 0 && (
                      <SportsGalleryItem>
                        <img src={RentalImageTwo} alt={"default image"} />
                      </SportsGalleryItem>
                    )}
                  </SportsGalleryBottom>
                </SportsGallery>
              </SportsDetailsTop>

              <SportsTitleWrap>
                <SportsTitle>
                  {hotelResultData?.data?.data?.[0]?.Name}
                </SportsTitle>
                <Dropdown>
                  <Dropdown.Toggle>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8" fill="#fff"></circle>
                      <path
                        d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                        transform="translate(2.459 16.62)"
                        fill="#3b3b3b"
                      ></path>
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      as="button"
                      onClick={() => {
                        setOpenChatModel(true);
                        groupPlanDetails();
                        shareChatImages(hotelResultData?.data?.data?.[0]);
                        setPlaceIdData(hotelResultData?.data?.data?.[0]?._id);
                      }}
                    >
                      Share to Chat
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => {
                        setPlansModal(true);
                        setRentalItemId(hotelResultData?.data?.data?.[0]?._id);
                      }}
                    >
                      Add to Plans
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => {
                        setRentalDetails({
                          venue_id: hotelResultData?.data?.data?.[0]?._id,
                          name: hotelResultData?.data?.data?.[0]?.Name,
                          location:
                            hotelResultData?.data?.data?.[0]?.Location?.Address,
                          province: "",
                          Image: "",
                          venue_type: "hotel",
                        });
                        setCollectionModal(true);
                      }}
                    >
                      Add to collection
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => {
                        setCreatePlanModalShow(true);
                        setCreateData(hotelResultData?.data?.data[0]);
                        setRentalItemId(hotelResultData?.data?.data?.[0]?._id);
                        setAllId([]);
                        setInviteFriend([]);
                        setInviteFollower([]);
                        setInviteEmails([]);
                        setGroupMembers([]);
                        setPendingEmail([]);
                        setShowImage("");
                        setImage("");
                        setImageValidation("");
                      }}
                    >
                      Create Plans
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        navigateToShareWith(hotelResultData?.data?.data[0])
                      }
                    >
                      Share With
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </SportsTitleWrap>

              <DetailRentalsFeature>
                <ul>
                  <li>
                    {/* {hotelResultData?.data?.data?.RoomTypes[0]
                            ?.Description ?? "No"}{" "} */}
                    Bedrooms
                  </li>
                  {/* <li>1 Bed</li> */}
                </ul>
              </DetailRentalsFeature>

              <DetailSportsInfo>
                <DetailSportsInfoWrap>
                  <DetailSportsRating>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Component_44_16"
                      data-name="Component 44 – 16"
                      width="22"
                      height="20.364"
                      viewBox="0 0 19 17.364"
                    >
                      <path
                        id="Polygon_1"
                        data-name="Polygon 1"
                        d="M9.5,0l2.375,6.457L19,6.632,13.343,10.8l2.028,6.566L9.5,13.481,3.629,17.364,5.657,10.8,0,6.632l7.125-.175Z"
                        fill="#ffc126"
                      />
                    </svg>
                    {hotelResultData?.data?.data?.[0]?.GuestRating ?? "0"}
                  </DetailSportsRating>
                  <DetailSportsReview>
                    {hotelResultData?.data?.data?.[0]?.GuestReviewCount ?? "0"}{" "}
                    Reviews
                  </DetailSportsReview>
                </DetailSportsInfoWrap>
                <DetailSportsPrice>
                  $
                  {
                    hotelResultData?.data?.data?.[0]?.RoomTypes?.[0]?.Price
                      ?.TotalPrice?.Value
                  }
                  /Night
                </DetailSportsPrice>
              </DetailSportsInfo>

              {hotelResultData?.data?.data?.[0]?.Description?.HotelTeaser && (
                <DetailSportsPrice className="sportsdetail">
                  {hotelResultData?.data?.data?.[0]?.Description?.HotelTeaser}
                </DetailSportsPrice>
              )}

              {hotelResultData?.data?.data?.[0]?.PetFriendly && (
                <DetailSportsPrice
                  className="sportsdetail"
                  style={{ display: "block", margin: "20px 0px" }}
                >
                  {hotelResultData?.data?.data?.[0]?.PetFriendly &&
                    "Pet Friendly"}
                </DetailSportsPrice>
              )}

              {hotelResultData?.data?.data?.[0]?.RoomTypes?.[0]
                ?.Description && (
                <DetailSportsPrice
                  className="sportsdetail"
                  style={{ display: "block", margin: "20px 0px" }}
                >
                  {
                    hotelResultData?.data?.data?.[0]?.RoomTypes?.[0]
                      ?.Description
                  }
                </DetailSportsPrice>
              )}

              <SportsTeamDesc>
                <p>
                  {
                    hotelResultData?.data?.data?.[0]?.Description
                      ?.LocationTeaser
                  }
                </p>
                <p>
                  You Need To Know{" "}
                  <strong>
                    This Property is managed by a professional host.The
                    provision of housing is linked to their trade,business or
                    profession
                  </strong>
                </p>
                <a
                  href={
                    hotelResultData?.data?.data?.[0]?.LINK_URL ??
                    hotelResultData?.data?.data?.[0]?.Links?.WebSearchResult
                      ?.Href
                  }
                  target="_blank"
                >
                  <Button type="button" className="btn">
                    VIEW MORE DETAILS
                  </Button>
                </a>
              </SportsTeamDesc>
            </SportsDetails>
          </HomeListingWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>

      <CreateNewModal
        show={createNewModalShow}
        onHide={() => setCreateNewModalShow(false)}
      />

      <ImageGalleryModal
        show={imageGalleryModalShow}
        onHide={() => setImageGalleryModalShow(false)}
        getAllImages={getAllImages}
      />

      <ShareWithModal
        show={shareWithModalShow}
        onHide={() => setShareWithModalShow(false)}
        name={shareWithName}
        link={shareWithLink}
        address={shareWithAddress}
      />
      <ChatsPlanModal
        show={openChatModel}
        onHide={() => setOpenChatModel(false)}
        groupImages={groupImages}
        placeIdData={placeIdData}
        dynamicLocation={dynamicLocation}
        allItems={allItems}
      />
      <AnotherDayModal
        show={timeDayModal}
        onHide={() => setTimeDayModal(false)}
        DayData={finalData}
        PlanId={planId}
        VenueId={rentalItemId}
        finalEvent={"hotel"}
      />
      <PlansModal
        show={plansModal}
        onHide={() => setPlansModal(false)}
        addPlanData={planData}
      />

      <GetAllCollectionsModal
        show={getCollectionModal}
        onHide={() => setCollectionModal(false)}
        getRentalDetails={getRentalDetails}
        setCollectionModal={setCollectionModal}
      />

      <CreatePlanModal
        show={createPlanModalShow}
        onHide={() => {
          setCreatePlanModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
        }}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
        pendingEmailRequest={pendingEmailRequest}
        getImageUrl={getImageUrl}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />

      <InviteOthersModal
        show={inviteOthersModalShow}
        onHide={() => setInviteOthersModalShow(false)}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setOpenTab={setOpenTab}
        openTab={openTab}
        getPlanDetails={getPlanDetails}
        setInviteFriend={setInviteFriend}
        inviteFriend={inviteFriend}
        setInviteFollower={setInviteFollower}
        inviteFollower={inviteFollower}
        setAllId={setAllId}
        getAllId={getAllId}
        planId={[]}
        setInviteEmails={setInviteEmails}
        inviteEmails={inviteEmails}
        setEmail={setEmail}
        getEmails={getEmails}
        setEmailValidate={setEmailValidate}
        emailValidate={emailValidate}
        setAllEmail={setAllEmail}
        getAllEmail={getAllEmail}
        setPendingEmail={setPendingEmail}
        pendingEmailRequest={pendingEmailRequest}
        pending_invite={[]}
        setGroupId={setGroupId}
        getGroupId={getGroupId}
        setGroupMembers={setGroupMembers}
        getAllUniqueGroupMember={getAllUniqueGroupMember}
        getImageUrl={getImageUrl}
        inviteGroupMembers={inviteGroupMembers}
      />
    </>
  );
};

export default HotelResultDetails;
