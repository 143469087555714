import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import Routing from "./routes/index";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "./redux/action/signin/user";
import { ToastContainer, toast, collapseToast } from "react-toastify";

// declare global {
//   interface Window {
//     // ⚠️ notice that "Window" is capitalized here
//     getToaster: any;
//   }
// }
function App(props: any) {
  const updateData = useSelector((state: any) => state?.updateUser);

  const dispatch = useDispatch();

  // const toaster = () => {
  //   toast.error("Token Expire");
  // };

  // useEffect(() => {
  //   if (window) {
  //     window.getToaster = function () {
  //       toast.error("Token Expire");
  //     };
  //   }
  // }, []);

  return (
    <div className="App">
      <Routing user={props?.user?.data} logout={props.logout} />
      {/* <ToastContainer autoClose={5000} /> */}
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
