import { inviteSignupAPI } from "../../../services/inviteSignup";

export enum InviteSignupType {
  INVITE_SIGNUP_INFO = "INVITE_SIGNUP_INFO",
  INVITE_SIGNUP_INFO_SUCCESS = "INVITE_SIGNUP_INFO_SUCCESS",
  INVITE_SIGNUP_INFO_FALIURE = "SIGNUP_INFO_FALIURE",
}

export const inviteSignupInfo = () => (dispatch: any) => {
  dispatch({
    type: InviteSignupType.INVITE_SIGNUP_INFO,
  });
};

export const inviteSignupInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: InviteSignupType.INVITE_SIGNUP_INFO_SUCCESS,
    data,
  });
};

export const inviteSignupInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: InviteSignupType.INVITE_SIGNUP_INFO_FALIURE,
    data: { msg },
  });
};

export const inviteSignupInfoAPIData =
  ({ email }: any) =>
  async (dispatch: any) => {
    try {
      dispatch(inviteSignupInfo());
      const result: any = await inviteSignupAPI({
        email,
      });

      dispatch(inviteSignupInfoSuccess(result));
    } catch (e: any) {
      console.error(e);
      dispatch(inviteSignupInfoFailed(e.message));
    }
  };
