import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { Dropdown, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";
import MainSearchPanel from "../../../components/mainSearchPanel/index";
import CreateNewModal from "../../../components/createNewModal/index";
import SportsComapanyLogo from "../../../assets/images/sports-list.jpg";
// import SportsPlaceholderImage from "../../../assets/images/Sports.svg";
import moment from "moment";
import { Pagination } from "antd";
import ShareWithModal from "../../../components/shareWithModal/index";
import Concert from "../../../assets/images/concert.jpg";

import {
  MyPageWrap,
  MainContentPanel,
  HomeListingWrap,
  HomeListingTags,
  CreateBtn,
  CreateBtnInner,
  FestivalsResultList,
  PaginationPage,
  FestivalsResultItem,
  FestivalsResultCalendarInner,
  FestivalsResultName,
  FestivalsResultTime,
  FestivalsResultBtnWrap,
  FestivalsResultDay,
  FestivalsResultCalendar,
  SportsList,
  FestivalsResultDate,
  FestivalsResultMonth,
  PaginationWrap,
  SearchListLoader,
  ResultPrice,
  SportsDetails,
  SportsDetailsTop,
  SportsLogo,
  SportsLogoInner,
  SportsTitleWrap,
  SportsTitle,
  RentalsFeature,
  SportsInfo,
  SportsTeamDesc,
  SportsPrice,
  RecordNotFound,
  BackToList,
  ModalSection,
} from "../sportsResults/sportsResultsStyle";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";
import { getSportsListData } from "../../../redux/action/get-sports-event/getSportsEvent";
import { getEventConcertData } from "../../../redux/action/get-event-concert/getEventConcert";
import GetAllCollectionsModal from "../../../components/collectionsModal";
import AnotherDayModal from "../../../components/anotherDayModal";
import PlansModal from "../../../components/plansModal";
import CreatePlanModal from "../../../components/createPlanModal/index";
import InviteOthersModal from "../../../components/inviteOthersModal/index";
import ChatsPlanModal from "../../../components/chatsPlanModal";
import { getGroupData } from "../../../redux/action/get-all-group/getAllGroups";
import { planListingData } from "../../../redux/action/plan-listing/planListing";
import CreateGroupModal from "../../../components/createGroupModal/index";
import { dynamicCategoryData } from "../../../redux/action/dynamic-category/dynamicCategory";
import { currentLocationInfoSuccess } from "../../../redux/action/current-location/currentLocation";
import { mapKey } from "../../../resource/constants";
import ConcertFestivalSportsImage from "../../assets/images/concert-festival-sports.png";
import Modal from "react-bootstrap/Modal";

const SportsResults = (props: any) => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const [getPageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPagelimit] = useState(20);
  const [sortingType, setSortingType] = useState("Price");
  const [showSortBy, setShowSortBy] = useState(-1);
  const [shareWithModalShow, setShareWithModalShow] = useState(false);
  // const [sharewithImage, setShareWithImage] = useState([]);
  const [sportsLink, setSportsLink] = useState("");
  const [sportsName, setSportsName] = useState("");
  const [allItems, setAllItems] = useState("");
  const [getSearchDate, setSearchDate] = useState(null);
  const [createGroupModalShow, setCreateGroupModalShow] = useState(false);

  const [getCollectionModal, setCollectionModal] = useState(false);
  const [getRentalDetails, setRentalDetails] = useState({
    venue_id: "",
    name: "",
    location: "",
    province: "",
    Image: "",
    venue_type: "",
  });
  const [openChatModel, setOpenChatModel] = useState(false);
  const [toDate, setTodate] = useState(null);
  const [groupImages, setGroupImages] = useState("");
  const [placeIdData, setPlaceIdData] = useState("");
  const [dynamicLocation, setDynamicLocation] = useState("");
  const [place, setPlace] = useState<any>({});
  const [show, setShow] = useState(false);

  const getSportsList = useSelector((state: any) => state?.getSportEventsList);

  const [backButton, setBackButton] = useState(false);
  const [getSearchResult, setSearchResult] = useState({
    search: "",
    location: "sports",
  });

  // location filter code start
  const latLong: any = useSelector((state: any) => state?.latLong);

  useEffect(() => {
    if (latLong?.data?.name) {
      console.log("gfsfdsds", latLong?.data?.name);
    }
  }, [latLong?.data?.name]);

  //location filter code finish

  const groupPlanDetails = () => {
    dispatch(getGroupData({ search: "" }));
    dispatch(planListingData());
  };
  const shareChatImages = (item: any) => {
    setGroupImages("");
    setAllItems(item);
    setDynamicLocation("/sports-results-details");
  };

  const local: any = JSON.parse(localStorage.getItem("location") || "{}");

  const currentLocation = useSelector((state: any) => state?.currentLocation);

  const location = useLocation();

  const locationPage: any = location.pathname.split("/")[2];

  useEffect(() => {
    if (!localStorage.getItem("location")) {
      setShow(true);
      dispatch({ type: "RESET_SPORTS_EVENT" });
    } else {
      (getSearchResult?.search?.trim()?.length >= 3 ||
        getSearchResult?.search?.trim() === "") &&
        dispatch(
          getSportsListData({
            page: Number(locationPage),
            limit: pageLimit,
            sort: showSortBy,
            sorting: sortingType,
            location:
              currentLocation?.data !== null
                ? currentLocation?.data
                : JSON.parse(localStorage.getItem("location") || "{}"),
            search: localStorage.getItem("main-search-panel-data")
              ? localStorage.getItem("main-search-panel-data")
              : getSearchResult?.search,

            date:
              getSearchDate === null
                ? ""
                : moment(getSearchDate).format("YYYY-MM-DD"),
            todate:
              toDate === null
                ? getSearchDate === null
                  ? ""
                  : moment(getSearchDate).format("YYYY-MM-DD")
                : moment(toDate).format("YYYY-MM-DD"),
          })
        );
    }
  }, [
    Number(locationPage),
    sortingType,
    showSortBy,
    getSearchDate,
    getSearchResult,
    toDate,
    place,
  ]);

  // useEffect(() => {
  //   dispatch(
  //     getSportsListData({
  //       page: Number(locationPage),
  //       limit: pageLimit,
  //       sort: showSortBy,
  //       sorting: sortingType,
  //       location:
  //         currentLocation?.data !== null
  //           ? currentLocation?.data
  //           : latLong?.data?.name
  //           ? latLong?.data?.name
  //           : typeof local !== "object" && local !== ""
  //           ? local
  //           : "",
  //       search: localStorage.getItem("main-search-panel-data")
  //         ? localStorage.getItem("main-search-panel-data")
  //         : getSearchResult?.search,
  //       date:
  //         getSearchDate === null
  //           ? ""
  //           : moment(getSearchDate).format("YYYY-MM-DD"),
  //       todate:
  //         toDate === null
  //           ? getSearchDate === null
  //             ? ""
  //             : moment(getSearchDate).format("YYYY-MM-DD")
  //           : moment(toDate).format("YYYY-MM-DD"),
  //     })
  //   );
  //   // dispatch({ type: "RESET_CURRENT_DATA" });
  // }, [
  //   Number(locationPage),
  //   sortingType,
  //   showSortBy,
  //   latLong?.data?.name,
  //   getSearchResult,
  //   currentLocation?.data,
  //   getSearchDate,
  //   toDate,
  //   place,
  // ]);

  const [createNewModalShow, setCreateNewModalShow] = useState(false);
  const [showConcertPage, setShowConncertPage] = useState(false);
  const [shareWithAddress, setShareWithAddress] = useState("");

  const navigateToShareWith: any = (item: any) => {
    setShareWithModalShow(true);
    setSportsLink(item?._links["event:webpage"]?.href);
    setSportsName(item?.name);
    setShareWithAddress(
      `${item?._embedded?.venue?.city} ${item?._embedded?.venue?.name} ${item?._embedded?.venue?.postal_code}`
    );
  };

  const [createPlanModalShow, setCreatePlanModalShow] = useState(false);
  const [createData, setCreateData] = useState("");
  const [getPlanDetails, setPlanDetails] = useState("Private");
  const [inviteFriend, setInviteFriend] = useState<string[]>([]);
  const [inviteFollower, setInviteFollower] = useState<string[]>([]);
  const [inviteOthersModalShow, setInviteOthersModalShow] = useState(false);
  const [getAllId, setAllId] = useState<string[]>([]);
  const [openTab, setOpenTab] = useState({
    group: false,
    follower: false,
    friend: false,
    email: false,
  });
  const [inviteEmails, setInviteEmails] = useState<any[]>([]);
  const [getEmails, setEmail] = useState("");
  const [emailValidate, setEmailValidate] = useState("");
  const [getAllEmail, setAllEmail] = useState<any[]>([]);
  const [pendingEmailRequest, setPendingEmail] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [getToken, setToken] = useState<any[]>([]);

  const [timeDayModal, setTimeDayModal] = useState(false);
  const [interestData, setInterestData] = useState("");

  const [plansModal, setPlansModal] = useState(false);
  const [interestId, setInterestId] = useState("");
  const [finalData, setFinalData] = useState<any[]>([]);
  const [planId, setPlanId] = useState("");
  const [getGroupId, setGroupId] = useState("");
  const [getAllGroupMember, setAllGroupMember] = useState<any[]>([]);
  const [getAllUniqueGroupMember, setAllUniqueGroupMember] = useState<any[]>(
    []
  );
  const [imageValidation, setImageValidation] = useState("");
  const [getImage, setImage] = useState("");
  const [showimage, setShowImage] = useState("");
  const [getImageUrl, setImageUrl] = useState("");
  const [inviteGroupMembers, setGroupMembers] = useState<string[]>([]);

  const planData = (e: any) => {
    setFinalData(e.days);
    setPlanId(e._id);
    setTimeDayModal(true);
  };

  const createPlan = useSelector((state: any) => state?.createPlan);
  const history: any = useNavigate();

  useEffect(() => {
    if (createData !== "" && createPlan?.data) {
      setFinalData(createPlan?.data?.data[0]?.days);
      setPlanId(createPlan?.data?.data[0]?._id);
      setTimeDayModal(true);
      setCreateData("");
      dispatch({ type: "CREATE_PLAN_RESET" });
    }
  }, [createPlan]);

  const group_data: any = useSelector((state: any) => state.createAllGroup);

  useEffect(() => {
    group_data?.data?.data[0]?.GroupList?.map((list: any, index: any) => {
      index === 0 && setGroupId(list?._id);
      list?.members?.map((ele: any) => {
        setAllGroupMember((prev: any) => [...prev, ele]);
      });
    });
    setImageUrl(group_data?.data?.data[0]?.link);
  }, [group_data]);

  useEffect(() => {
    const unqiueMembers = [
      ...getAllGroupMember
        .reduce((a, c) => {
          a.set(c._id, c);
          return a;
        }, new Map())
        .values(),
    ];

    setAllUniqueGroupMember(unqiueMembers);
  }, [getAllGroupMember]);

  const navigateToNextPage = (value: any) => {
    navigate(`/sportsresults/${value}`);
  };

  return (
    <>
      <Header logout={props.logout} />
      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
            centered
          >
            <ModalSection>
              <Modal.Body>
                <p>Please Select Location First</p>
                <button
                  className="btn btn-primary"
                  onClick={() => setShow(false)}
                >
                  Ok
                </button>
              </Modal.Body>
            </ModalSection>
          </Modal>

          <MainSearchPanel
            setSearchResult={setSearchResult}
            getSearchResult={getSearchResult}
            setPageNumber={setPageNumber}
            setSearchDate={setSearchDate}
            getSearchDate={getSearchDate}
            setTodate={setTodate}
            toDate={toDate}
            place={place}
            setPlace={setPlace}
          />

          <HomeListingWrap>
            <HomeListingTags>
              <Link
                to="/"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                All
              </Link>
              <Link
                to="/concertsresults/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Concerts
              </Link>
              <Link
                to="/foodanddrinks/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Food &amp; Drinks
              </Link>
              <Link
                to="/festivalsresults/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Festivals
              </Link>
              <Link
                to="/sportsresults/1"
                className="btn select"
                onClick={() => {
                  setShowConncertPage(false);
                  localStorage.removeItem("main-search-panel-data");
                }}
              >
                Sports
              </Link>
              <Link
                to="/rentalsresults/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Rentals
              </Link>
              <Link
                to="/hotelsresult/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Hotels
              </Link>
              <Link
                to="/interestresults/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Points of Interest
              </Link>
              <Link
                to="/hiking/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Hiking
              </Link>
            </HomeListingTags>

            <CreateBtn>
              <CreateBtnInner>
                {getSportsList?.data?.data?.Sports?.length > 0 && (
                  <Dropdown className="sortby" align="start">
                    <Dropdown.Toggle>
                      Sort by
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.656"
                        height="8.596"
                        viewBox="0 0 13.656 8.596"
                      >
                        <path
                          id="Path_133"
                          data-name="Path 133"
                          d="M5.944,11.888,0,5.944,5.944,0"
                          transform="translate(0.884 6.828) rotate(-90)"
                          fill="none"
                          stroke="#3b3b3b"
                          stroke-width="2.5"
                        />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        as="button"
                        style={{
                          color:
                            sortingType === "Price" && showSortBy === -1
                              ? "#6840df"
                              : "",
                        }}
                        onClick={() => {
                          setPageNumber(1);
                          setShowSortBy(-1);
                          setSortingType("Price");
                        }}
                      >
                        Price : High to Low
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        style={{
                          color:
                            sortingType === "Price" && showSortBy === 1
                              ? "#6840df"
                              : "",
                        }}
                        onClick={() => {
                          setPageNumber(1);
                          setShowSortBy(1);
                          setSortingType("Price");
                        }}
                      >
                        Price : Low to High
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                {getSportsList?.data?.data?.Sports?.length !== 0 &&
                  getSportsList?.isLoading !== true &&
                  localStorage.getItem("location") && (
                    <button
                      type="submit"
                      className="btn"
                      onClick={() => setCreateNewModalShow(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                      >
                        <g transform="translate(-1016 -337)">
                          <path
                            d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z"
                            transform="translate(1016 337)"
                            fill="#fff"
                          />
                          <path
                            d="M5.38-8.554V-4.21H1.148v2.732H5.38V2.865H8.335V-1.479h4.232V-4.21H8.335V-8.554Z"
                            transform="translate(1020.143 350.845)"
                            fill="#4d27da"
                            stroke="#fff"
                            stroke-width="0.5"
                            className="plusIcon"
                          />
                        </g>
                      </svg>
                      CREATE
                    </button>
                  )}
              </CreateBtnInner>
            </CreateBtn>

            {getSportsList?.isLoading && (
              <SearchListLoader>
                <img src={PageLoaderIcon} alt="Loading..." />
              </SearchListLoader>
            )}

            {(getSportsList?.data?.data?.Sports?.length === 0 ||
              !localStorage.getItem("location")) && (
              <RecordNotFound>No data found!</RecordNotFound>
            )}

            <>
              <FestivalsResultList>
                {getSportsList?.data?.data?.Sports.map((item: any) => {
                  return (
                    <>
                      <FestivalsResultItem>
                        <FestivalsResultCalendar>
                          <FestivalsResultCalendarInner>
                            <FestivalsResultDate>
                              {moment(item?.start_date, "YYYY/MM/DD").date()}
                            </FestivalsResultDate>
                            <FestivalsResultMonth>
                              {1 +
                                moment(item?.start_date, "YYYY/MM/DD").month()}
                            </FestivalsResultMonth>
                            <FestivalsResultDay>
                              {moment(item?.start_date).format("ddd")}
                            </FestivalsResultDay>
                          </FestivalsResultCalendarInner>
                          <FestivalsResultTime>
                            {moment(item?.start_date).format("hh:mm a")}
                          </FestivalsResultTime>
                        </FestivalsResultCalendar>
                        <FestivalsResultName>
                          <h3>
                            <Link to={`/sports-results-details/${item._id}`}>
                              {item?.name}
                            </Link>
                          </h3>
                          <p>{` ${item?._embedded?.venue?.city},
                           ${item?._embedded?.venue?.name} `}</p>
                          <ResultPrice>
                            $ {item?.min_ticket_price?.amount ?? "0"}
                            {/* /Night */}
                          </ResultPrice>
                        </FestivalsResultName>
                        <FestivalsResultBtnWrap>
                          <a
                            type="submit"
                            target="_blank"
                            className="btn"
                            href={item?._links?.["event:webpage"]?.href}
                          >
                            Buy Tickets
                          </a>
                          <Dropdown>
                            <Dropdown.Toggle>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                viewBox="0 0 16 16"
                              >
                                <circle
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  fill="#fff"
                                ></circle>
                                <path
                                  d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                  transform="translate(2.459 16.62)"
                                  fill="#3b3b3b"
                                ></path>
                              </svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                as="button"
                                onClick={() => {
                                  setOpenChatModel(true);
                                  groupPlanDetails();
                                  shareChatImages(item);
                                  setPlaceIdData(item?._id);
                                }}
                              >
                                Share to Chat
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => {
                                  setPlansModal(true);
                                  setInterestId(item?._id);
                                  setInterestData(item);
                                }}
                              >
                                Add to Plans
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => {
                                  setRentalDetails({
                                    venue_id: item?._id,
                                    name: item?.name,
                                    location: "",
                                    province: "",
                                    Image: item?.photo,
                                    venue_type: "sports",
                                  });
                                  setCollectionModal(true);
                                }}
                              >
                                Add to collection
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => {
                                  setCreatePlanModalShow(true);
                                  setCreateData(item);
                                  setInterestId(item?._id);
                                  setInterestData(item);
                                  setAllId([]);
                                  setInviteFriend([]);
                                  setInviteFollower([]);
                                  setInviteEmails([]);
                                  setGroupMembers([]);
                                  setPendingEmail([]);
                                  setShowImage("");
                                  setImage("");
                                  setImageValidation("");
                                }}
                              >
                                Create Plans
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => navigateToShareWith(item)}
                              >
                                Share With
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </FestivalsResultBtnWrap>
                      </FestivalsResultItem>
                    </>
                  );
                })}

                {getSportsList?.data?.data?.Sports?.length !== 0 &&
                  localStorage.getItem("location") && (
                    <PaginationWrap>
                      <Pagination
                        total={getSportsList?.data?.data?.count}
                        pageSize={pageLimit}
                        showSizeChanger={false}
                        onChange={(value, pagesize) => {
                          setPageNumber(value);
                          setPagelimit(20);
                          navigateToNextPage(value);
                        }}
                        current={Number(locationPage)}
                      />
                    </PaginationWrap>
                  )}
              </FestivalsResultList>
            </>
          </HomeListingWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>

      <CreateNewModal
        show={createNewModalShow}
        onHide={() => setCreateNewModalShow(false)}
        setCreateNewModalShow={setCreateNewModalShow}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setAllId={setAllId}
        setInviteFriend={setInviteFriend}
        setInviteFollower={setInviteFollower}
        setInviteEmails={setInviteEmails}
        setGroupMembers={setGroupMembers}
        setPendingEmail={setPendingEmail}
        setCreateGroupModalShow={setCreateGroupModalShow}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />

      <CreatePlanModal
        show={createPlanModalShow}
        onHide={() => {
          setCreatePlanModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
        }}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
        pendingEmailRequest={pendingEmailRequest}
        getImageUrl={getImageUrl}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />

      <InviteOthersModal
        show={inviteOthersModalShow}
        onHide={() => setInviteOthersModalShow(false)}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setOpenTab={setOpenTab}
        openTab={openTab}
        getPlanDetails={getPlanDetails}
        setInviteFriend={setInviteFriend}
        inviteFriend={inviteFriend}
        setInviteFollower={setInviteFollower}
        inviteFollower={inviteFollower}
        setAllId={setAllId}
        getAllId={getAllId}
        planId={[]}
        setInviteEmails={setInviteEmails}
        inviteEmails={inviteEmails}
        setEmail={setEmail}
        getEmails={getEmails}
        setEmailValidate={setEmailValidate}
        emailValidate={emailValidate}
        setAllEmail={setAllEmail}
        getAllEmail={getAllEmail}
        setPendingEmail={setPendingEmail}
        pendingEmailRequest={pendingEmailRequest}
        pending_invite={[]}
        setGroupId={setGroupId}
        getGroupId={getGroupId}
        setGroupMembers={setGroupMembers}
        getAllUniqueGroupMember={getAllUniqueGroupMember}
        getImageUrl={getImageUrl}
        inviteGroupMembers={inviteGroupMembers}
      />

      <CreateGroupModal
        show={createGroupModalShow}
        onHide={() => {
          setCreateGroupModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
          setGroupMembers([]);
          setPendingEmail([]);
        }}
        setCreateGroupModalShow={setCreateGroupModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
      />

      <ShareWithModal
        show={shareWithModalShow}
        onHide={() => setShareWithModalShow(false)}
        // sharewithImage={sharewithImage}
        link={sportsLink}
        name={sportsName}
        address={shareWithAddress}
      />

      <GetAllCollectionsModal
        show={getCollectionModal}
        onHide={() => setCollectionModal(false)}
        getRentalDetails={getRentalDetails}
        setCollectionModal={setCollectionModal}
      />

      <AnotherDayModal
        show={timeDayModal}
        onHide={() => setTimeDayModal(false)}
        DayData={finalData}
        PlanId={planId}
        VenueId={interestId}
        finalEvent={"sports"}
        interestData={interestData}
      />
      <ChatsPlanModal
        show={openChatModel}
        onHide={() => setOpenChatModel(false)}
        groupImages={groupImages}
        placeIdData={placeIdData}
        dynamicLocation={dynamicLocation}
        allItems={allItems}
      />

      <PlansModal
        show={plansModal}
        onHide={() => setPlansModal(false)}
        addPlanData={planData}
      />
    </>
  );
};

export default SportsResults;
