import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";
import MainSearchPanel from "../../../components/mainSearchPanel/index";

import WelcomeModal from "../../../components/welcomeModal/index";
import ShareWithModal from "../../../components/shareWithModal/index";
import CreateNewModal from "../../../components/createNewModal/index";
import CreateGroupModal from "../../../components/createGroupModal/index";
import CreatePlanModal from "../../../components/createPlanModal/index";
import CollectionsModal from "../../../components/collectionsModal/index";
import PlansModal from "../../../components/plansModal/index";
import ChatPlansGroupModal from "../../../components/chatsPlanModal/index";
import InviteOthersModal from "../../../components/inviteOthersModal/index";
import FiltersModal from "../../../components/filtersModal/index";

import ConcertsImage from "../../../assets/images/concerts-image.png";
import FoodDrinksImage from "../../../assets/images/food-drinks-image.png";
import FestivalsImage from "../../../assets/images/festivals-image.png";
import SportsImage from "../../../assets/images/sports-image.png";
import RentalsImage from "../../../assets/images/rentals-image.png";
import HotelsImage from "../../../assets/images/hotels-image.png";
import POIImage from "../../../assets/images/poi-image.png";
import HikingImage from "../../../assets/images/hiking-image.png";
import { currentLocationInfoSuccess } from "../../../redux/action/current-location/currentLocation";

import {
  MyPageWrap,
  MainContentPanel,
  HomeListingWrap,
  HomeListingTags,
  CreateBtn,
  HomeListing,
  ListItem,
  ListItemName,
} from "./myPlansStyle";

import "react-datepicker/dist/react-datepicker.css";

import { dynamicCategoryData } from "../../../redux/action/dynamic-category/dynamicCategory";
import { mapKey } from "../../../resource/constants";

const MyPlans = (props: any) => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const [welcomeModalShow, setWelcomeModalShow] = useState(false);
  const [createNewModalShow, setCreateNewModalShow] = useState(false);
  const [shareWithModalShow, setShareWithModalShow] = useState(false);
  const [createPlanModalShow, setCreatePlanModalShow] = useState(false);
  const [createGroupModalShow, setCreateGroupModalShow] = useState(false);
  const [collectionsModalShow, setCollectionsModalShow] = useState(false);
  const [plansCreatedModalShow, setPlansCreatedModalShow] = useState(false);
  const [chatPlansGroupModalShow, setChatPlansGroupModalShow] = useState(false);
  const [filtersModalShow, setFiltersModalShow] = useState(false);
  const [getPlanDetails, setPlanDetails] = useState("Private");
  const [getPageNumber, setPageNumber] = useState(1);
  const [inviteFriend, setInviteFriend] = useState<string[]>([]);
  const [inviteFollower, setInviteFollower] = useState<string[]>([]);

  const [inviteOthersModalShow, setInviteOthersModalShow] = useState(false);
  const [getAllId, setAllId] = useState<string[]>([]);
  const [openTab, setOpenTab] = useState({
    group: false,
    follower: false,
    friend: false,
    email: false,
  });
  const [inviteEmails, setInviteEmails] = useState<any[]>([]);
  const [getEmails, setEmail] = useState("");
  const [emailValidate, setEmailValidate] = useState("");

  const [pendingEmailRequest, setPendingEmail] = useState<any[]>([]);
  const [getGroupId, setGroupId] = useState("");
  const [getAllGroupMember, setAllGroupMember] = useState<any[]>([]);
  const [getAllUniqueGroupMember, setAllUniqueGroupMember] = useState<any[]>(
    []
  );
  const [getImageUrl, setImageUrl] = useState("");
  const [getAllEmail, setAllEmail] = useState<any[]>([]);
  const createPlan = useSelector((state: any) => state?.createPlan);
  const [inviteGroupMembers, setGroupMembers] = useState<string[]>([]);
  const [getAllTab, setAllTab] = useState("ALL");
  const [imageValidation, setImageValidation] = useState("");
  const [getImage, setImage] = useState("");
  const [showimage, setShowImage] = useState("");

  const group_data: any = useSelector((state: any) => state.createAllGroup);

  useEffect(() => {
    group_data?.data?.data[0]?.GroupList?.map((list: any, index: any) => {
      index === 0 && setGroupId(list?._id);
      list?.members?.map((ele: any) => {
        setAllGroupMember((prev: any) => [...prev, ele]);
      });
    });
    setImageUrl(group_data?.data?.data[0]?.link);
  }, [group_data]);

  useEffect(() => {
    const unqiueMembers = [
      ...getAllGroupMember
        .reduce((a, c) => {
          a.set(c._id, c);
          return a;
        }, new Map())
        .values(),
    ];

    setAllUniqueGroupMember(unqiueMembers);
  }, [getAllGroupMember]);

  useEffect(() => {
    dispatch(dynamicCategoryData());
  }, []);

  const getDynamicCategories = useSelector(
    (state: any) => state?.getDynamicCategory
  );

  const dynamicRoute = (item: any) => {
    if (item?.type === "concert") {
      navigate("/concertsresults");
    }
    if (item?.type === "food & drinks") {
      navigate("/foodAndDrinks");
    }
    if (item?.type === "festivals") {
      navigate("/festivalsresults");
    }
    if (item?.type === "sports") {
      navigate("/sportsresults");
    }
    if (item?.type === "rentals") {
      navigate("/rentalsresults");
    }
    if (item?.type === "hotels") {
      navigate("/hotelsresult");
    }
    if (item?.type === "poi") {
      navigate("/interestresults");
    }
    if (item?.type === "hiking") {
      navigate("/hiking");
    }
  };

  useEffect(() => {
    dispatch({ type: "RESET_CURRENT_DATA" });
  }, []);

  return (
    <>
      <Header logout={props.logout} />

      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <MainSearchPanel
            getAllTab={getAllTab}
            setPageNumber={setPageNumber}
          />

          <HomeListingWrap>
            <HomeListingTags>
              <Link to="/" className="btn select">
                All
              </Link>
              <Link to="/concertsresults/1" className="btn">
                Concerts
              </Link>
              <Link to="/foodanddrinks/1" className="btn">
                Food &amp; Drinks
              </Link>
              <Link to="/festivalsresults/1" className="btn">
                Festivals
              </Link>
              <Link to="/sportsresults/1" className="btn">
                Sports
              </Link>
              <Link to="/rentalsresults/1" className="btn">
                Rentals
              </Link>
              <Link to="/hotelsresult/1" className="btn ">
                Hotels
              </Link>
              <Link to="/interestresults/1" className="btn">
                Points of Interest
              </Link>
              <Link to="/hiking/1" className="btn">
                Hiking
              </Link>
            </HomeListingTags>

            <CreateBtn>
              <button
                type="submit"
                className="btn"
                onClick={() => setFiltersModalShow(true)}
                style={{ marginRight: "20px", display: "none" }}
              >
                Filter
              </button>

              <button
                type="submit"
                className="btn"
                onClick={() => setChatPlansGroupModalShow(true)}
                style={{ marginRight: "20px", display: "none" }}
              >
                Chat
              </button>

              <button
                type="submit"
                className="btn"
                onClick={() => setPlansCreatedModalShow(true)}
                style={{ marginRight: "20px", display: "none" }}
              >
                Plans
              </button>

              <button
                type="submit"
                className="btn"
                onClick={() => setCollectionsModalShow(true)}
                style={{ marginRight: "20px", display: "none" }}
              >
                Collections
              </button>

              <button
                type="submit"
                className="btn"
                onClick={() => props.setInviteOthersModalShow(true)}
                style={{ marginRight: "20px", display: "none" }}
              >
                INVITE OTHERS
              </button>

              <button
                type="submit"
                className="btn"
                onClick={() => setCreateGroupModalShow(true)}
                style={{ marginRight: "20px", display: "none" }}
              >
                CREATE A GROUP
              </button>

              <button
                type="submit"
                className="btn"
                onClick={() => setCreatePlanModalShow(true)}
                style={{ marginRight: "20px", display: "none" }}
              >
                CREATE A PLAN
              </button>

              <button
                type="submit"
                className="btn"
                onClick={() => setShareWithModalShow(true)}
                style={{ marginRight: "20px", display: "none" }}
              >
                SHARE WITH
              </button>

              <button
                type="submit"
                className="btn"
                onClick={() => setWelcomeModalShow(true)}
                style={{ marginRight: "20px", display: "none" }}
              >
                Welcome to Tripd
              </button>

              <button
                type="submit"
                className="btn"
                onClick={() => setCreateNewModalShow(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                >
                  <g transform="translate(-1016 -337)">
                    <path
                      d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z"
                      transform="translate(1016 337)"
                      fill="#fff"
                    />
                    <path
                      d="M5.38-8.554V-4.21H1.148v2.732H5.38V2.865H8.335V-1.479h4.232V-4.21H8.335V-8.554Z"
                      transform="translate(1020.143 350.845)"
                      fill="#4d27da"
                      stroke="#fff"
                      stroke-width="0.5"
                      className="plusIcon"
                    />
                  </g>
                </svg>
                CREATE
              </button>
            </CreateBtn>

            <HomeListing>
              <ul>
                {getDynamicCategories?.data?.data?.map((item: any) => {
                  return (
                    <>
                      <li onClick={() => dynamicRoute(item)}>
                        <ListItem>
                          <Link to="#">
                            <img
                              src={item?.category_image}
                              alt={item?.category_name}
                            />
                            <ListItemName>{item?.category_name}</ListItemName>
                          </Link>
                        </ListItem>
                      </li>
                    </>
                  );
                })}

                {/* <li>
                  <ListItem>
                    <Link to="/foodanddrinks">
                      <img src={FoodDrinksImage} alt="food-drinks-image" />
                      <ListItemName>FOOD &amp; DRINKS</ListItemName>
                    </Link>
                  </ListItem>
                </li>
                <li>
                  <ListItem>
                    <Link to="/festivalsresults">
                      <img src={FestivalsImage} alt="festivals-image" />
                      <ListItemName>FESTIVALS</ListItemName>
                    </Link>
                  </ListItem>
                </li>
                <li>
                  <ListItem>
                    <Link to="/sportsresults">
                      <img src={SportsImage} alt="sports-image" />
                      <ListItemName>SPORTS</ListItemName>
                    </Link>
                  </ListItem>
                </li>
                <li>
                  <ListItem>
                    <Link to="/rentalsresults">
                      <img src={RentalsImage} alt="rentals-image" />
                      <ListItemName>RENTALS</ListItemName>
                    </Link>
                  </ListItem>
                </li>
                <li>
                  <ListItem>
                    <Link to="/hotelsresult">
                      <img src={HotelsImage} alt="hotels-image" />
                      <ListItemName>HOTELS</ListItemName>
                    </Link>
                  </ListItem>
                </li>
                <li>
                  <ListItem>
                    <Link to="/interestresults">
                      <img src={POIImage} alt="poi-image" />
                      <ListItemName>POI</ListItemName>
                    </Link>
                  </ListItem>
                </li>
                <li>
                  <ListItem>
                    <Link to="/hiking">
                      <img src={HikingImage} alt="hiking-image" />
                      <ListItemName>HIKING</ListItemName>
                    </Link>
                  </ListItem>
                </li> */}
              </ul>
            </HomeListing>
          </HomeListingWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>

      <WelcomeModal
        show={welcomeModalShow}
        onHide={() => setWelcomeModalShow(false)}
      />

      <CreateNewModal
        show={createNewModalShow}
        onHide={() => setCreateNewModalShow(false)}
        setCreateNewModalShow={setCreateNewModalShow}
        setCreateGroupModalShow={setCreateGroupModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setOpenTab={setOpenTab}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setAllId={setAllId}
        setInviteFriend={setInviteFriend}
        setInviteFollower={setInviteFollower}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        setGroupMembers={setGroupMembers}
        setPendingEmail={setPendingEmail}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />

      <ShareWithModal
        show={shareWithModalShow}
        onHide={() => setShareWithModalShow(false)}
      />

      <CreatePlanModal
        show={createPlanModalShow}
        onHide={() => {
          setCreatePlanModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
          setGroupMembers([]);
          setPendingEmail([]);
        }}
        setCreatePlanModalShow={setCreatePlanModalShow}
        pendingEmailRequest={pendingEmailRequest}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getImageUrl={getImageUrl}
        getAllEmail={getAllEmail}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />

      <CreateGroupModal
        show={createGroupModalShow}
        onHide={() => {
          setCreateGroupModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
          setGroupMembers([]);
          setPendingEmail([]);
        }}
        setCreateGroupModalShow={setCreateGroupModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        pendingEmailRequest={pendingEmailRequest}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
      />

      <InviteOthersModal
        show={inviteOthersModalShow}
        onHide={() => setInviteOthersModalShow(false)}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setOpenTab={setOpenTab}
        openTab={openTab}
        getPlanDetails={getPlanDetails}
        setInviteFriend={setInviteFriend}
        inviteFriend={inviteFriend}
        setInviteFollower={setInviteFollower}
        inviteFollower={inviteFollower}
        setAllId={setAllId}
        getAllId={getAllId}
        planId={[]}
        setInviteEmails={setInviteEmails}
        inviteEmails={inviteEmails}
        setEmail={setEmail}
        getEmails={getEmails}
        setEmailValidate={setEmailValidate}
        emailValidate={emailValidate}
        setAllEmail={setAllEmail}
        getAllEmail={getAllEmail}
        setPendingEmail={setPendingEmail}
        pendingEmailRequest={pendingEmailRequest}
        pending_invite={[]}
        setGroupId={setGroupId}
        getGroupId={getGroupId}
        setGroupMembers={setGroupMembers}
        getAllUniqueGroupMember={getAllUniqueGroupMember}
        getImageUrl={getImageUrl}
        inviteGroupMembers={inviteGroupMembers}
      />

      <CollectionsModal
        show={collectionsModalShow}
        onHide={() => setCollectionsModalShow(false)}
      />

      <PlansModal
        show={plansCreatedModalShow}
        onHide={() => setPlansCreatedModalShow(false)}
      />

      <ChatPlansGroupModal
        show={chatPlansGroupModalShow}
        onHide={() => setChatPlansGroupModalShow(false)}
      />

      <FiltersModal
        show={filtersModalShow}
        onHide={() => setFiltersModalShow(false)}
      />
    </>
  );
};

export default MyPlans;
