import { loginAPI } from "../../../services/login";
import { setUser } from "./user";

export enum LoginType {
  LOGIN_INFO = "LOGIN_INFO",
  LOGIN_INFO_SUCCESS = "LOGIN_INFO_SUCCESS",
  LOGIN_INFO_FALIURE = "LOGIN_INFO_FALIURE",
}

export const loginInfo = () => (dispatch: any) => {
  dispatch({
    type: LoginType.LOGIN_INFO,
  });
};

export const loginInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: LoginType.LOGIN_INFO_SUCCESS,
    data: data,
  });
};

export const loginInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: LoginType.LOGIN_INFO_FALIURE,
    data: { msg },
  });
};

export const userLoginDataAPI =
  ({ email, password }: any) =>
  async (dispatch: any) => {
    try {
      dispatch(loginInfo());
      const result: any = await loginAPI({
        email,
        password,
      });

      dispatch(setUser(result.data));

      localStorage.setItem("user", JSON.stringify(result.data));
      localStorage.setItem("token", result?.data?.token);
      localStorage.setItem("id", result?.data?.id);

      dispatch(loginInfoSuccess(result));
    } catch (e: any) {
      dispatch(loginInfoFailed(e.response.data.message));
    }
  };
